import { useMemo } from "react"
import { connect } from "react-redux"
import {
  F,
  compose,
  filter,
  assoc,
  identity,
  props,
  find,
  propEq,
  propOr,
  equals,
  reject,
  includes,
  isEmpty,
  intersection,
  prop,
} from "ramda"
import { noop } from "@ninjaone/utils"
import { DataTable } from "@ninjaone/components"
import { cellTypes, filterTypes } from "@ninjaone/components/src/DataTable"
import { localized, user, localizationKey, isNotEmpty } from "js/includes/common/utils"
import { Flex } from "js/includes/components/Styled"
import { getScriptCategories } from "js/state/selectors/scripting/scriptCategories"
import { automationOsIcons } from "js/includes/components/scripting/utils"
import { chooseFileToImport } from "js/includes/configuration/scripting/importScriptFile"
import showModal from "js/includes/common/services/showModal"
import InstallApplicationModal from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/InstallApplication/InstallApplicationModal"
import RunApplicationModal from "js/includes/components/scripting/ScriptsSelector/ParameterModal/ParameterComponents/RunApplicationModal"
import { findAutomationIconName } from "js/includes/components/scripting/AutomationIcon"

const SCRIPT_AUTOMATION_ID = "ScriptAutomation"

export const ScriptsDataTable = ({
  isEditingEnabled = F,
  isDeletingEnabled = F,
  isCopyingEnabled = F,
  hideSettingsButton = false,
  isEditableRows = true,
  onEditClick = noop,
  onDeleteClick = noop,
  onCopyClick = noop,
  onRowSelected,
  scriptingLanguages,
  automationTypes,
  automationTypesExcept,
  scriptCategories,
  operatingSystems,
  showDescription = false,
  showAddButton = true,
  showAutomationTypeFilter = true,
  scriptsList,
  fetchAutomationsResources,
  setAutomationsUnderReview,
  sortBy = [],
  defaultDeviceTypes = [],
  defaultAutomationTypes = [],
  scriptsLoading,
}) => {
  const automationTypesMap = useMemo(
    () =>
      scriptingLanguages.reduce((acc, { automationType, id }) => {
        if (acc[automationType]) {
          acc[automationType].push(id)
        } else {
          acc[automationType] = [id]
        }

        return acc
      }, {}),
    [scriptingLanguages],
  )

  const automationTypesData = useMemo(() => {
    if (!automationTypesExcept) {
      return automationTypes
    }

    return reject(({ id }) => includes(id, automationTypesExcept), automationTypes)
  }, [automationTypes, automationTypesExcept])

  const automationTypeOptions = useMemo(() => {
    return automationTypesData.map(({ labelToken, id }) => ({ label: localized(labelToken), value: id }))
  }, [automationTypesData])

  const getOSOptions = useMemo(
    () =>
      defaultDeviceTypes.length
        ? defaultDeviceTypes.map(name => ({ label: name, value: name }))
        : operatingSystems.map(({ value, id }) => ({ label: value, value: id })),
    [defaultDeviceTypes, operatingSystems],
  )

  const onEdit = ({ selected }) => {
    onEditClick(selected[0], setAutomationsUnderReview)
  }

  const onDelete = ({ selected }) => {
    onDeleteClick(selected[0])
  }

  const onCopy = ({ selected }) => {
    onCopyClick(selected[0])
  }

  const getScriptingLanguage = rowData => {
    return scriptingLanguages?.find(l => l.id === rowData.language)?.value || ""
  }

  const hideEditAction = rowData => {
    return !isEditingEnabled(rowData)
  }

  const hideDeleteAction = rowData => {
    return !isDeletingEnabled(rowData)
  }

  const hideCopyAction = rowData => {
    return !isCopyingEnabled(rowData)
  }

  const actions = [
    ...(isEditableRows
      ? [
          {
            labelToken: localizationKey("Edit"),
            action: onEdit,
            hideRowAction: hideEditAction,
          },
          {
            labelToken: localizationKey("Copy"),
            action: onCopy,
            hideRowAction: hideCopyAction,
          },
          {
            labelToken: localizationKey("Delete"),
            action: onDelete,
            isRed: true,
            hideRowAction: hideDeleteAction,
          },
        ]
      : []),
  ]

  const columns = [
    {
      Header: localized("Name"),
      id: "name",
      accessor: ({ customName, name }) => customName || name,
      maxWidth: "450px",
      minWidth: "400px",
      bold: true,
      ...(showDescription && { getDescription: row => row.description }),
      getIconName: automation =>
        automation.binaryInstallSettings?.customIconAttachmentId
          ? ""
          : findAutomationIconName(scriptingLanguages, automation.language),

      getImg: automation => {
        return automation.binaryInstallSettings?.customIconAttachmentId
          ? {
              size: 32,
              attachmentId: automation.binaryInstallSettings.customIconAttachmentId,
              defaultIconName: "ScriptAutomationIcon",
            }
          : {}
      },
    },
    {
      Header: localized("OS"),
      id: "deviceType",
      accessor: () => "",
      disableSortBy: true,
      minWidth: "70px",
      maxWidth: "70px",
      getCellTypeProps: row => ({
        type: cellTypes.LAZY_RENDERER,
        props: {
          renderer: () => {
            const MainIcon = automationOsIcons[defaultDeviceTypes?.[0]?.toLowerCase() || row.deviceType.toLowerCase()]
            const SecondaryIcon =
              row.operatingSystems.length > 1 &&
              isEmpty(defaultDeviceTypes) &&
              automationOsIcons[row.operatingSystems[1]?.toLowerCase()]
            return (
              <Flex>
                <MainIcon />
                {SecondaryIcon && <SecondaryIcon />}
              </Flex>
            )
          },
        },
      }),
    },
    {
      Header: localized("Type"),
      id: "language",
      accessor: getScriptingLanguage,
    },
    {
      Header: localized("Categories"),
      id: "categoryName",
      minWidth: "200px",
      textWrap: true,
      textWrapLineLimit: 2,
      accessor: "categoriesNames",
      getCellTypeProps: script => ({
        type: cellTypes.TAGS,
        props: script.categoriesTags,
      }),
    },
  ]

  const primaryFilters = {
    primary: [
      ...(defaultDeviceTypes.length === 1
        ? []
        : [
            {
              name: "deviceType",
              type: filterTypes.MULTISELECT,
              labelToken: localizationKey("OS"),
              filter: ({ row, value: selectedSystems }) =>
                row.operatingSystems.some(os => selectedSystems.includes(os)),
              componentProps: {
                options: getOSOptions,
              },
            },
          ]),
      ...(showAutomationTypeFilter
        ? [
            {
              name: "automationType",
              type: filterTypes.MULTISELECT,
              filter: ({ row, value: selectedAutomations }) => {
                return (props(selectedAutomations, automationTypesMap) || []).flat().includes(row.language)
              },
              onFilterChange: ({ value, updateFilterProps }) => {
                const scriptAutomationSelected = value.includes(SCRIPT_AUTOMATION_ID)
                updateFilterProps(
                  "language",
                  compose(
                    scriptAutomationSelected ? identity : assoc("value", []),
                    assoc("hidden", !scriptAutomationSelected && !isEmpty(value)),
                  ),
                )
              },
              labelToken: localizationKey("Type"),
              initialValue: defaultAutomationTypes,
              componentProps: {
                options: automationTypeOptions,
              },
            },
          ]
        : []),
      ...(!defaultAutomationTypes.length || defaultAutomationTypes.includes(SCRIPT_AUTOMATION_ID)
        ? [
            {
              name: "language",
              type: filterTypes.MULTISELECT,
              labelToken: localizationKey("Language"),
              componentProps: {
                options: scriptingLanguages
                  .filter(language => language.automationType === SCRIPT_AUTOMATION_ID)
                  .map(({ value, id }) => ({ label: value, value: id })),
              },
              filter: ({ row, value: selectedLanguages, allFilters }) => {
                const selectedAutomations = compose(
                  reject(equals(SCRIPT_AUTOMATION_ID)),
                  propOr([], "value"),
                  find(propEq("name", "automationType")),
                )(allFilters)

                return (props(selectedAutomations, automationTypesMap) || [])
                  .flat()
                  .concat(selectedLanguages)
                  .includes(row.language)
              },
            },
          ]
        : []),
      {
        name: "categoryName",
        type: filterTypes.MULTISELECT,
        filter: ({ row, value }) => {
          const scriptCategoriesList = filter(id => value.includes(id), row.categoriesIds)
          return scriptCategoriesList.length
        },
        labelToken: localizationKey("Categories"),
        componentProps: {
          options: scriptCategories.map(({ id, name }) => ({ label: name, value: id })),
        },
      },
    ],
  }

  const showAddActionsButton = {
    actions: [
      ...(user("canCreateAtLeastOneScript")
        ? [
            {
              titleToken: localizationKey("SCRIPTS"),
              id: "SCRIPTS",
            },
            {
              labelToken: localizationKey("New script"),
              action: () => (window.location.hash = "#/editor/script/new"),
            },
            {
              labelToken: localizationKey("Import from file"),
              action: () => chooseFileToImport(),
            },
            {
              labelToken: localizationKey("Import from template"),
              splitAfter: true,
              action: () => (window.location.hash = "#/administration/library/scripting/templateLibrary"),
            },
            {
              titleToken: localizationKey("APP AUTOMATION"),
              id: "APP AUTOMATION",
            },
            {
              labelToken: localizationKey("Installation"),
              action: () => {
                showModal(<InstallApplicationModal setAutomationsUnderReview={setAutomationsUnderReview} />, {
                  withProvider: true,
                })
              },
            },
            {
              labelToken: localizationKey("Run"),
              action: () => {
                showModal(<RunApplicationModal setAutomationsUnderReview={setAutomationsUnderReview} />, {
                  withProvider: true,
                })
              },
            },
          ]
        : []),
    ],
  }

  const getScripsList = useMemo(
    () =>
      defaultDeviceTypes.length
        ? filter(compose(isNotEmpty, intersection(defaultDeviceTypes), prop("operatingSystems")), scriptsList)
        : scriptsList,
    [defaultDeviceTypes, scriptsList],
  )

  return (
    <Flex height="100%" width="100%">
      <div className="full-width">
        <DataTable
          hideCheckboxes
          tableId="scripts-table"
          onRefresh={fetchAutomationsResources}
          columns={columns}
          initialSortBy={sortBy}
          loading={scriptsLoading}
          rows={getScripsList}
          noRowsToken={localizationKey("No data found")}
          actions={{
            primary: actions,
            row: {
              action:
                onRowSelected ||
                (rowData => isEditingEnabled(rowData) && onEditClick(rowData, setAutomationsUnderReview)),
            },
          }}
          filters={primaryFilters}
          pageSizeLimit={50}
          globalActionsButton={showAddButton ? showAddActionsButton : {}}
          {...{ hideSettingsButton }}
        />
      </div>
    </Flex>
  )
}

export default connect(state => ({
  scriptingLanguages: state.scripting.languages,
  automationTypes: state.scripting.automationTypes,
  scriptCategories: getScriptCategories(state),
  operatingSystems: state.scripting.operatingSystems,
  scriptsLoading: state.scripting.scriptsLoading,
}))(ScriptsDataTable)
