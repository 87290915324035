import React from "react"
import { connect } from "react-redux"
import { Modal, Text } from "@ninjaone/components"
import { useMountedState } from "js/includes/common/hooks"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import { enableMaintenanceOnDevices } from "js/state/actions/deviceSearch/maintenance"
import MaintenanceFooter from "./MaintenanceFooter"

const ConfirmMaintenanceModal = ({
  deviceIds,
  schedule,
  options,
  enableMaintenanceOnDevices,
  fetchDevicesInMaintenance,
  unmount,
  dispatchCallback,
  fromDeviceSearch,
  activeSearch,
}) => {
  const [isSaving, setIsSaving] = useMountedState(false)
  const [hasError, setHasError] = useMountedState(false)
  const handleSubmit = async () => {
    setHasError(false)
    try {
      setIsSaving(true)
      await enableMaintenanceOnDevices({
        deviceIds,
        schedule,
        options,
        fetchDevicesInMaintenance,
        dispatchCallback,
        fromDeviceSearch,
        activeSearch,
      })
      unmount()
    } catch (error) {
      if (!error.isDevicesCacheExpiredError) {
        setHasError(true)
      }
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Modal
      cancelable
      unmount={unmount}
      buttons={[
        {
          type: "primary",
          labelToken: localizationKey("Override"),
          disabled: isSaving,
          onClick: handleSubmit,
        },
      ]}
      titleGroup={{
        titleText: localized("Override current settings?"),
      }}
    >
      <Text type="body" textWrap>
        {fromDeviceSearch
          ? localized("Settings for devices that are already in maintenance mode will be overridden.")
          : deviceIds.length > 1
          ? localized(
              "Current selection includes devices currently in maintenance mode. Proceeding will override the current maintenance mode settings",
            )
          : localized(
              "This device is currently in maintenance mode. Proceeding will override the current maintenance mode settings.",
            )}
      </Text>
      <MaintenanceFooter hasError={hasError} />
    </Modal>
  )
}

export default connect(null, { enableMaintenanceOnDevices })(ConfirmMaintenanceModal)
