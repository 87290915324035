import { useMemo } from "react"
import { connect } from "react-redux"
import moment from "moment"
import PropTypes from "prop-types"
import { pluck } from "ramda"
import { Modal } from "@ninjaone/components"
import { Heading } from "@ninjaone/components"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localizationKey, localized } from "js/includes/common/utils"
import { getSeconds } from "js/includes/common/utils/ssrAndWebUtils"
import { useForm, useMountedState } from "js/includes/common/hooks"
import HelpTooltip from "js/includes/components/HelpTooltip"
import showModal from "js/includes/common/services/showModal"
import { getSuggestedArticles } from "js/includes/components/HelpDropdown/SuggestedArticles/common"
import { enableMaintenanceOnDevices } from "js/state/actions/deviceSearch/maintenance"
import Schedule from "./StartEndSchedule/Schedule"
import { defaultSchedule, allSuppressOptions } from "./StartEndSchedule/scheduleDataDefaults"
import SuppressOptions from "./SuppressOptions"
import ConfirmMaintenanceModal from "./ConfirmMaintenanceModal"
import { modalValidation } from "./modalValidation"
import MaintenanceFooter from "./MaintenanceFooter"
import {
  isExpiredTime,
  areAllDevicesTheSameType,
  getDisabledOptions,
  getSuppressOptionsByDeviceType,
  findDeviceWithMaintenanceMode,
} from "./methods"

const getSelectedScheduled = ({ start, end }) => {
  return {
    startTime: start.type === "AT" ? getSeconds(start.datetime) : null,
    endTime: end.type === "ON" ? getSeconds(end.datetime) : null,
  }
}

const EnableMaintenanceModal = ({
  nodes,
  fetchDevicesInMaintenance,
  unmount,
  dispatchCallback,
  enableMaintenanceOnDevices,
  isInDeviceSearchSelectAllMode,
  fromDeviceSearch,
  activeSearch,
}) => {
  const [hasError, setHasError] = useMountedState(false)
  const [isSaving, setIsSaving] = useMountedState(false)
  const [node] = nodes
  const maintenance = nodes.length === 1 && node.maintenanceMode
  const allNodesSameType = useMemo(() => nodes.length === 1 || areAllDevicesTheSameType(nodes), [nodes])
  const endTimeExpired = maintenance && isExpiredTime(maintenance.endTime)

  const maintenanceStart = maintenance?.startTime && {
    type: "AT",
    datetime: moment.unix(maintenance.startTime).valueOf(),
  }

  const maintenanceEnd = maintenance?.endTime && {
    type: "ON",
    datetime: moment.unix(maintenance.endTime).valueOf(),
  }

  const helpMaintenanceDescription = localized(
    "Maintenance mode allows you to suppress selected features for a device or devices for a designated window of time.",
  )

  const maintenanceOptions =
    maintenance?.disabledOptions && getDisabledOptions(maintenance.disabledOptions, node.nodeClass)

  const defaultSuppressOptions =
    allNodesSameType && !isInDeviceSearchSelectAllMode
      ? getSuppressOptionsByDeviceType(node.nodeClass)
      : allSuppressOptions

  const {
    values: { start, end, options },
    validation,
    validateForm,
    onChange,
  } = useForm({
    fields: {
      start: (!endTimeExpired && maintenanceStart) || defaultSchedule().start,
      end: (!endTimeExpired && maintenanceEnd) || defaultSchedule().end,
      options: (!endTimeExpired && maintenanceOptions) || defaultSuppressOptions,
    },
    validate: modalValidation,
    validateOnChange: true,
  })

  const handleChange = data => {
    setHasError(false)
    onChange(data)
  }

  const onSetMaintenance = async () => {
    setHasError(false)
    if (validateForm()) {
      setIsSaving(true)
      const schedule = getSelectedScheduled({ start, end })
      const deviceIds = pluck("id", nodes)
      try {
        if (isInDeviceSearchSelectAllMode || findDeviceWithMaintenanceMode(nodes)) {
          showModal(
            <ConfirmMaintenanceModal
              {...{
                deviceIds,
                schedule,
                options,
                fetchDevicesInMaintenance,
                dispatchCallback,
                fromDeviceSearch,
                activeSearch,
              }}
            />,
            { withProvider: true },
          )
        } else {
          await enableMaintenanceOnDevices({
            deviceIds,
            schedule,
            options,
            fetchDevicesInMaintenance,
            dispatchCallback,
            fromDeviceSearch,
          })
        }
        unmount()
      } catch (error) {
        if (!error.isDevicesCacheExpiredError) {
          setHasError(true)
        }
      } finally {
        setIsSaving(false)
      }
    }
  }

  return (
    <Modal
      minHeight="700px"
      submittable
      titleGroup={{
        TitleComponent: () => (
          <Flex alignItems="center">
            <Heading level={2} type="headingL">
              {localized("Enable maintenance mode")}
            </Heading>
            <HelpTooltip
              articles={getSuggestedArticles(["360051137451"])}
              triggerSize="sm"
              description={helpMaintenanceDescription}
            />
          </Flex>
        ),
      }}
      buttons={[
        {
          type: "save",
          labelToken: localizationKey("Enable"),
          onClick: onSetMaintenance,
          disabled: isSaving,
        },
      ]}
      cancelable
      unmount={unmount}
    >
      <Schedule
        {...{
          validation,
          schedule: { start, end },
          onScheduleUpdate: schedule => {
            handleChange({ ...schedule })
          },
        }}
      />

      <SuppressOptions
        {...{
          options,
          onChange: handleChange,
        }}
      />
      <MaintenanceFooter {...{ hasDeviceTypeMessage: !allNodesSameType, hasError }} />
    </Modal>
  )
}

EnableMaintenanceModal.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchDevicesInMaintenance: PropTypes.func,
  unmount: PropTypes.func.isRequired,
  dispatchCallback: PropTypes.func,
  enableMaintenanceOnDevices: PropTypes.func.isRequired,
  isInDeviceSearchSelectAllMode: PropTypes.bool,
  activeSearch: PropTypes.object,
}

export default connect(
  state => ({
    activeSearch: state.deviceSearch,
  }),
  { enableMaintenanceOnDevices },
)(EnableMaintenanceModal)
