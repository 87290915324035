import { fullScreenEditorCloseHandler } from "@ninjaone/utils"
import ButtonGroup from "../ButtonGroup"
import { StyledGrid } from "../Styled"
import { StyledEditor } from "../Styled/Editor"

export default function Editor({
  appLogo,
  children,
  onSave,
  onClose,
  disabled,
  shouldHandleFullScreenEditorClose = true,
  preButtonsRenderer,
  buttonHeaderIsFullWidth = true,
}) {
  return (
    <StyledEditor>
      <StyledGrid>
        <header>
          <a href="/#/systemDashboard/overview">
            <img src={appLogo} alt="logo" />
          </a>
          {preButtonsRenderer?.()}
          <ButtonGroup
            fullWidth={buttonHeaderIsFullWidth}
            buttons={[
              { hide: !onSave, type: "save", onClick: onSave, disabled, onMouseDown: e => e.preventDefault() },
              {
                type: "close",
                async onClick() {
                  if (onClose) {
                    const { preventClose } = (await onClose()) || {}
                    if (preventClose) return
                  }
                  if (shouldHandleFullScreenEditorClose) {
                    fullScreenEditorCloseHandler()
                  }
                },
                onMouseDown: e => e.preventDefault(),
              },
            ]}
          />
        </header>

        {children}
      </StyledGrid>
    </StyledEditor>
  )
}
