import {
  fetch,
  showSuccessMessage,
  showErrorMessage,
  localized,
  localizationKey,
  runDeviceSearchToolbarAction,
} from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import ShowMessageDialog from "js/includes/components/MessageDialog"
import { faEraser } from "@fortawesome/pro-solid-svg-icons"

export const enableMaintenanceOnDevices = ({
  deviceIds,
  schedule,
  options,
  fetchDevicesInMaintenance,
  dispatchCallback,
  fromDeviceSearch,
  activeSearch,
}) => async dispatch => {
  const requestBody = {
    schedule,
    options,
    ...(!fromDeviceSearch && { deviceIds }),
  }

  const response = fromDeviceSearch
    ? await runDeviceSearchToolbarAction({
        action: "RUN_MAINTENANCE_CONFIGURE",
        data: requestBody,
      })
    : await fetch("/maintenance", { options: { method: "POST", body: JSON.stringify(requestBody) } })
  if (!fromDeviceSearch && response.status !== 204) {
    throw new NinjaResponseError(response)
  } else {
    showSuccessMessage()
    fetchDevicesInMaintenance && fetchDevicesInMaintenance()
    dispatchCallback && dispatchCallback({ type: "CLEAR_SELECTION" })
  }
}

export const disableMaintenanceOnDevices = ({
  deviceIds,
  deviceName,
  fetchDevicesInMaintenance,
  dispatchCallback,
  fromDeviceSearch,
  activeSearch,
}) => async dispatch => {
  try {
    const buttonPressed = await ShowMessageDialog({
      icon: { icon: faEraser, type: "critical" },
      title: () => localized("Disable"),
      MessageComponent: () => (
        <p>
          {fromDeviceSearch
            ? localized(
                "Are you sure you want to disable maintenance for all devices in maintenance mode that meet the selected criteria?",
              )
            : `${localized("Are you sure you want to disable the maintenance for")} ${
                deviceName ? deviceName : deviceIds.length
              } ${localized("device(s)?")}`}
          <br />
        </p>
      ),
      buttons: [
        { id: "YES", label: localizationKey("Yes") },
        { id: "NO", label: localizationKey("No") },
      ],
    })

    if (buttonPressed === "YES") {
      const response = fromDeviceSearch
        ? await runDeviceSearchToolbarAction({
            action: "RUN_MAINTENANCE_DISABLE",
          })
        : await fetch("/maintenance/schedules/deleteList", {
            options: { method: "POST", body: JSON.stringify({ deviceIds }) },
          })

      if (!fromDeviceSearch && response?.status !== 204) {
        throw new NinjaResponseError(response)
      } else {
        showSuccessMessage()
        fetchDevicesInMaintenance && fetchDevicesInMaintenance()
        dispatchCallback && dispatchCallback({ type: "CLEAR_SELECTION" })
      }
    }
  } catch (error) {
    if (!error.isDevicesCacheExpiredError) {
      showErrorMessage(localized("Failed to disable maintenance."))
    }
  }
}
